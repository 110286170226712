<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      width="800px"
      :mask-closable="false"
      title="新增文档"
      :ok-button-props="{ props: { loading: submitting } }"
      @ok="handleSubmit"
    >
      <a-form
        class="sfm-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="文档名称">
          <a-input
            v-decorator="['name', {
              rules: [
                { required: true, message: '请输入文档名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="文件" required>
          <div>支持DOC/DOCX/PPT/PDF/XLS/XLSX/TXT格式文件，大小不超过20GB</div>
            <a-upload
              name="file"
              accept=".doc, .docx, .ppt, .pptx, .pdf, .xls, .xlsx, .txt"
              :file-list="fileList"
              :before-upload="handleBeforeUploadDocument"
              :custom-request="handleUploadDocument"
              :remove="handleRemoveDocument"
              v-decorator="['document',{
                rules:[
                  {validator: checkDocument}
                ]
              }]"
            >
              <a-icon type="loading" v-if="documentloading" />
              <a v-if="fileList.length === 0">
                点击上传
              </a>
            </a-upload>
        </a-form-item>

        <a-form-item label="大小">
          {{ fileSizeDisplay }}
        </a-form-item>

        <a-form-item label="格式">
          {{ fileType }}
        </a-form-item>

        <a-form-item label="宣传图">
          <div>支持图片格式：PNG、JPG，10M以内，推荐图标尺寸210mm*297px</div>
          <a-upload
            accept=".png,.jpg"
            :file-list="posterFileList"
            :remove="handleRemovePoster"
            :before-upload="handleBeforeUploadPoster"
            :custom-request="handleUploadPoster"
          >
            <a-icon type="loading" v-if="posterloading" />
              <a v-if="posterFileList.length === 0">
                点击上传
              </a>
          </a-upload>
        </a-form-item>

        <a-form-item label="文档授权">
          <a-radio-group
            v-decorator="['is_free',{
              initialValue: false,
              rules: [
                { required: true, message: '请选择文档授权' },
              ]
            }]"
            @change="handleChangeIsFree"
          >
            <a-radio :value="false">
              收费
            </a-radio>
            <a-radio :value="true">
              免费
            </a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="价格(积分)" v-if="!isFree">
          <a-input-number
            :min="1"
            :max="10000000"
            :precision="0"
            :step="1"
            style="width: 100%"
            v-decorator="['point', {
              rules: [
                { required: true, message: '请输入价格(积分)' },
              ]
            }]"
          />
        </a-form-item>

        <a-form-item label="推荐">
          <a-checkbox
            v-decorator="['is_recommend',{
              valuePropName: 'checked',
              initialValue: false
            }]"
          />
        </a-form-item>

        <a-form-item label="显示">
          <a-checkbox
            v-decorator="['visible',{
              valuePropName: 'checked',
              initialValue: true
            }]"
          />
        </a-form-item>

        <a-form-item label="一级分类">
          <a-select
            v-decorator="['first_category_id']"
            placeholder="请选择一级分类"
            showSearch
            allow-clear
            :filter-option="filterOption"
            :loading="loadingFirstCateOptions"
            @focus="fetchFirstCateOptions"
            @change="handleFirstCateChange"
          >
            <a-select-option
              v-for="item in firstCateOptions"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="二级分类">
          <a-select
            v-decorator="['second_category_id']"
            placeholder="请选择二级分类"
            allow-clear
            showSearch
            :filter-option="filterOption"
            :loading="loadingSecondCateOptions"
            @change="handleSecondCateChange"
          >
            <a-select-option
              v-for="item in secondCateOptions"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

         <a-form-item label="三级分类">
          <a-select
            v-decorator="['third_category_id']"
            placeholder="请选择三级分类"
            showSearch
            :filter-option="filterOption"
            allow-clear
            :loading="loadingThirdCateOptions"
          >
            <a-select-option
              v-for="item in thirdCateOptions"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="标签">
          <a-textarea
            v-decorator="['label']"
            placeholder="请为文档打上标签，每个标签不超过5个字，标签之间用顿号隔开，便签数量不超过10个"
            autocomplete="off"
            :auto-size="{ minRows: 3, maxRows: 5 }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { DocumentCategoryModel } from "@/models/document_category.js"
const documentCategoryModel = new DocumentCategoryModel()
import { DocumentModel } from "@/models/document.js"
const documentModel = new DocumentModel()
import upload from "@/utils/oss";
import { bToMbGb } from '@/utils/filter.js'
import { FileChecksum } from "@/utils/fileChecksum";

export default {
  name: 'CreateDocumentModal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    rootId: {
      type: Number,
      required: true
    },
    parentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'new_document' }),
      submitting: false,
      posterloading: false,
      documentloading: false,
      isFree: false,
      firstCateOptions: [],
      secondCateOptions: [],
      thirdCateOptions: [],
      loadingFirstCateOptions: false,
      loadingSecondCateOptions: false,
      loadingThirdCateOptions: false,
      searchingRootId: 0,
      maxDocumentSize: 20 * 1024 * 1024 * 1024,
      maxPosterSize: 10 * 1024 * 1024,
      fileList: [],
      posterFileList: [],
      fileSize: 0,
      fileSizeDisplay: undefined,
      fileType: '',
      fileName: '',
      fileChecksum: '',
      documentKey: '',
      posterKey: '',
      posterSize: 0,
      posterType: '',
      posterName: '',
      posterChecksum: '',
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    handleBeforeUploadDocument(file) {
      if (file.size > this.maxDocumentSize) {
        this.$notification.error({
          message: '提示',
          description: `文件大小不能超过 ${this.maxDocumentSize / 1024.0 / 1024.0 / 1024} GB`,
          duration: 2
        })
        file.status = 'error'
        return false
      }

      // 文件类型是否合法
      const fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (!['doc', 'docx', 'ppt', 'pptx', 'pdf', 'xls', 'xlsx', 'txt'].includes(fileType)) {
        this.$notification.error({
          message: '提示',
          description: `文件格式不正确`,
          duration: 2
        })
        file.status = 'error'
        return false
      }
    },

    handleUploadDocument(options) {
      const { file } = options
      this.documentloading = true
      this.fileList.push(file)
      const fileChecksum = new FileChecksum(file)
      fileChecksum.create((error, checksum) => {
        upload(file, "softmall-documents", {}).then((key) => {
          if (key) {
            this.documentKey = key
            this.fileSize = file.size
            this.fileSizeDisplay = bToMbGb(file.size)
            this.fileType = file.type
            this.fileName = file.name
            this.fileChecksum = checksum
            this.documentloading = false
          } else {
            this.fileList = []
          }
        })
      })
    },

    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },

    handleRemoveDocument() {
      this.fileList = []
      this.documentKey = ''
      this.fileSize = undefined
      this.fileType = ''
    },

    handleBeforeUploadPoster(file) {
      if (file.size > this.maxPosterSize) {
        this.$notification.error({
          message: '提示',
          description: `文件大小不能超过 ${this.maxPosterSize / 1024.0 / 1024.0} MB`,
          duration: 2
        })
        file.status = 'error'
        return false
      }

      // 文件类型是否合法
      const fileType = file.name.substring(file.name.lastIndexOf('.') + 1)
      if (!['png', 'jpg', 'jpeg'].includes(fileType)) {
        this.$notification.error({
          message: '提示',
          description: `文件格式不正确`,
          duration: 2
        })
        file.status = 'error'
        return false
      }
    },

    handleUploadPoster(options) {
      this.posterloading = true
      const { file } = options
      const fileChecksum = new FileChecksum(file)
      fileChecksum.create((error, checksum) => {
        upload(file, "softmall-documents", {}).then((key) => {
          if (key) {
            this.posterKey = key
            this.posterSize = file.size
            this.posterType = file.type
            this.posterName = file.name
            this.posterChecksum = checksum
            this.posterFileList.push(file)
            this.posterloading = false
          }
        })
      })
    },

    handleRemovePoster() {
      this.posterFileList = []
      this.posterKey = ''
      this.posterSize = undefined
      this.posterType = ''
    },

    fetchFirstCateOptions() {
      if (this.firstCateOptions.length === 0) {
        this.loadingFirstCateOptions = true
        const data = {
          root_id: 0,
          parent_id: 0
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.firstCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingFirstCateOptions = false
        })
      }
    },

    // 检查是否上传文档
    checkDocument(rule, value, callback) {
      if (this.fileList.length === 0 || this.fileList[0].status === 'error') {
        callback('请上传文件')
        return
      }

      callback()
    },

    handleFirstCateChange(firstCateId) {
      this.secondCateOptions = []
      this.thirdCateOptions = []
      this.searchingRootId = 0

      this.form.setFieldsValue({
        second_category_id: undefined,
        third_category_id: undefined
      })

      if (firstCateId) {
        this.searchingRootId = firstCateId
        this.fetchSecondCateOptions({ root_id: firstCateId, parent_id: firstCateId })
      }
    },

    handleSecondCateChange(secondCateId) {
      this.thirdCateOptions = []

      this.form.setFieldsValue({
        third_category_id: undefined
      })

      if (secondCateId) {
        this.fetchThirdCateOptions({ root_id: this.searchingRootId, parent_id: secondCateId })
      }
    },

    fetchSecondCateOptions(params) {
      if (this.secondCateOptions.length === 0) {
        this.loadingSecondCateOptions = true
        const data = {
          root_id: params.root_id,
          parent_id: params.parent_id
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.secondCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingSecondCateOptions = false
        })
      }
    },

    fetchThirdCateOptions(params) {
      if (this.thirdCateOptions.length === 0) {
        this.loadingThirdCateOptions = true
        const data = {
          root_id: params.root_id,
          parent_id: params.parent_id
        }
        documentCategoryModel.options(data).then(res => {
          if (res.code === 0) {
            res.result.forEach(item => {
              this.thirdCateOptions.push({
                'id': item.id,
                'name': item.name
              })
            })
          }
          this.loadingThirdCateOptions = false
        })
      }
    },

    handleChangeIsFree(e) {
      this.isFree = e.target.value
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const params = {
            ...values,
            file_blob: {
              key: this.documentKey,
              filename: this.fileName,
              byte_size: this.fileSize,
              content_type: this.fileType,
              checksum: this.fileChecksum,
            },
            poster_blob: {
              key: this.posterKey,
              filename: this.posterName,
              byte_size: this.posterSize,
              content_type: this.posterType,
              checksum: this.posterChecksum,
            }
          }
          delete params.document
          documentModel.create(params).then((res) => {
            if (res.code === 0) {
              this.$notification.open({
                message: '提示',
                description: res.message,
                duration: 2
              })
              this.isShow = false
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-form-item {
  margin-bottom: 5px;
}

.avatar-uploader > .ant-upload {
  width: 110px;
  height: 110px;
  float: left;
}

.icon {
  width: 86px;
}
</style>
